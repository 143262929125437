@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Noto+Naskh+Arabic:wght@400..700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
  font-family: "Tangerine";
  font-style: normal;
  font-weight: normal;
  src: local("Tangerine"), url("/public/captcha3.ttf") format("truetype");
}

body {
  display: block;
  vertical-align: middle;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

input {
  outline: none;
}

.arabic {
  font-family: "Noto Naskh Arabic", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.cairo {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.captcha {
  font-family: "Tangerine", serif;
  font-size: 48px;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: initial;
  vertical-align: initial;
}

table {
  width: 100%;
  table-layout: fixed;
}
th,
td {
  white-space: normal;
  word-wrap: break-word;
}
th p {
  margin: 0;
}
